import "../css/components/MainMenu.scss"
import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { getWindowSize } from '../scripts/utils'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons"

interface MainMenuProps {
    openMenu: boolean
    setOpenMenu: any
    isLoggedIn: boolean
    userData: any
}

function MainMenu({ openMenu, setOpenMenu, isLoggedIn, userData }: MainMenuProps) {

    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [pokemonSubMenu, setPokemonSubMenu] = useState<boolean>(false)
    const [adminSubMenu, setAdminSubMenu] = useState<boolean>(false)

    useEffect(() => {
        if (!openMenu) {
            setAdminSubMenu(false)
            setPokemonSubMenu(false)
        }
    }, [openMenu])

    return (
        <ul id='main-menu' className={openMenu ? "main-menu-open" : "main-menu"}>
            <NavLink
                className={({ isActive }) => (isActive ? 'btn-menu nav-active' : 'btn-menu')}
                to={"/"} onClick={() => setOpenMenu((prev: any) => !prev)}>
                {/*<FontAwesomeIcon icon={faHome} size='xs' /> */}
                Home
            </NavLink>
            <NavLink
                className={({ isActive }) => (isActive ? 'btn-menu nav-active' : 'btn-menu')}
                to={"/shop"} onClick={() => setOpenMenu((prev: any) => !prev)}>
                {/*<FontAwesomeIcon icon={faShop} size='xs' /> */}
                Shop
            </NavLink>


            {isLoggedIn &&
                <NavLink
                    className={({ isActive }) => (isActive ? 'btn-menu nav-active' : 'btn-menu')}
                    to={"/purchase-history"} onClick={() => setOpenMenu((prev: any) => !prev)}>
                    {/*<FontAwesomeIcon icon={faShop} size='xs' /> */}
                    Riscatti
                </NavLink>
            }
            {
                windowSize.innerWidth < 960 && isLoggedIn &&
                <NavLink
                    className={({ isActive }) => (isActive ? 'btn-menu nav-active' : 'btn-menu')}
                    to={"/profile"} onClick={() => setOpenMenu((prev: any) => !prev)}>
                    {/*<FontAwesomeIcon icon={faShop} size='xs' /> */}
                    Profilo
                </NavLink>
            }

            {/*
            isLoggedIn &&
                <a
                    className={'btn-menu'} onClick={() => setPokemonSubMenu((prev) => !prev)}>
                    Pokemon
                    {
                        pokemonSubMenu ?
                            <FontAwesomeIcon icon={faChevronUp} className="btn-menu-icon" />
                            :
                            <FontAwesomeIcon icon={faChevronDown} className="btn-menu-icon" />
                    }

                </a>
                */
            }


            {
                //POKEMON SUBMENU
                /*
                pokemonSubMenu &&
                <div className="sub-menu">
                    <NavLink
                        className={({ isActive }) => (isActive ? 'btn-sub-menu nav-active' : 'btn-sub-menu btn-sub-menu')}
                        to={"/fantapoke/map"} onClick={() => { setOpenMenu((prev: boolean) => !prev) }}>
                        
                        FantaPokemon
                    </NavLink>
                    <NavLink
                        className={({ isActive }) => (isActive ? 'btn-sub-menu nav-active' : 'btn-sub-menu btn-sub-menu')}
                        to={"/fantapoke/box"} onClick={() => { setOpenMenu((prev: any) => !prev) }}>
                        
                        Box Pokemon
                    </NavLink>
                    <NavLink
                        className={({ isActive }) => (isActive ? 'btn-sub-menu nav-active' : 'btn-sub-menu btn-sub-menu')}
                        to={"/fantapoke/ranking"} onClick={() => { setOpenMenu((prev: any) => !prev) }}>
                        
                        Classifica
                    </NavLink>
                </div>
                */
            }

            {
                // ADMIN
                userData.is_admin &&
                <a
                    className={'btn-menu'} onClick={() => setAdminSubMenu((prev) => !prev)}>
                    Admin
                    {
                        adminSubMenu ?
                            <FontAwesomeIcon icon={faChevronUp} className="btn-menu-icon" />
                            :
                            <FontAwesomeIcon icon={faChevronDown} className="btn-menu-icon" />
                    }

                </a>

            }
            {
                userData.is_admin && adminSubMenu &&
                <div className="sub-menu">
                    <NavLink className="btn-sub-menu" target='_blank' to={"/admin"} onClick={() => setOpenMenu((prev: any) => !prev)}>
                        Generale
                    </NavLink>
                    <NavLink className="btn-sub-menu" target='_blank' to={"/admin/pokemon"} onClick={() => setOpenMenu((prev: any) => !prev)}>
                        Pokemon
                    </NavLink>
                    <NavLink className="btn-sub-menu" target='_blank' to={"/admin/pokemon_auction"} onClick={() => setOpenMenu((prev: any) => !prev)}>
                        Aste Pokemon
                    </NavLink>
                    <NavLink className="btn-sub-menu" target='_blank' to={"/admin/pokemon_scores"} onClick={() => setOpenMenu((prev: any) => !prev)}>
                        Scores Pokemon
                    </NavLink>
                    <NavLink className="btn-sub-menu" target='_blank' to={"/admin/betting"} onClick={() => setOpenMenu((prev: any) => !prev)}>
                        Betting
                    </NavLink>
                </div>
            }

            <div className="social-links">
                {process.env.REACT_APP_YOUTUBE &&
                    <a className="youtube" href={process.env.REACT_APP_YOUTUBE} target='_blank'>
                        <img src='/img/social/youtube.png' />
                    </a>
                }

                {process.env.REACT_APP_INSTAGRAM &&
                    <a className="instagram" href={process.env.REACT_APP_INSTAGRAM} target='_blank'>
                        <img src='/img/social/instagram.png' />
                    </a>
                }

                {process.env.REACT_APP_TWITCH &&
                    <a className="twitch" href={process.env.REACT_APP_TWITCH} target='_blank'>
                        <img src='/img/social/twitch.png' />
                    </a>
                }

            </div>
        </ul>
    )
}

export default MainMenu